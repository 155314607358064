.contactForm{
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 10px;
    max-width: 60%;
  border: 1px solid #ccc;
  box-sizing: border-box;
margin: auto;

}
.contactButton{
    background-color: #2d9dc9;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
@import url('https://fonts.googleapis.com/css2?family=YujiMai:wght@200;400"&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth; 
  font-family: 'YujiMai', sans-serif;
}
.App{
 text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(245, 245, 245, 0.349);
  overflow: hidden;
}
h1{
  padding-top: 1%;
}
p{
padding-right: 15%;
padding-left: 20%;
font-size:large;
color: black;
}

.Video{
  margin-top: 20px;
}
#shisasasa{

  max-height: 500px;
  max-width: 600px;
  min-height: 250px;
  min-width: 300px;
  width: 50%;
  height: 50%


}
.footer {
  position: static;
  margin-top: auto; 
  background-color: rgb(34, 29, 29); color: beige;  
  bottom:0;
  width:100%;
  text-align: center;
  }
/*
footer{
 
 
  text-align: center;
  position:absolute;

    
}*/

.schedule{
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 90%;
  box-sizing: border-box;
  margin: auto;
 
}


.social-media a:hover{
  background: lightblue;
  color:rgb(212, 138, 212);
 box-shadow: 0 0 5rem;
}
    
.bg{

  background: rgb(136,0,27);
  background: linear-gradient(90deg, rgba(136,0,27,1) 0%, rgba(96,170,207,1) 35%, rgba(240,182,56,1) 100%);
  

}

#demo{
  margin-right: 15%;
  float: right;
  max-width: 30.55%;
}
#demo1{
  margin-left: 20%;
float: left;
max-width:30.55%;
}

#jonManji{
 
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}
#testing2007{
 min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}
#campPhoto{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}
#raulCer{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}

#guyle{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}
#kobuNis{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width:50%;
}

#ouch{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}

#eskrima{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width: 50%;
}

#camKama{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width:50%; 
}

#thumbsUp{
  min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width:50%; 
}

#saing{
   min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width:50%; 
}

#maceplumar{
   min-height: 50%;
  max-height: 100%;
  height: 50%;
  max-width: 150%;
  min-width: 75%;
  width:50%; 
}
.Video{
  gap: 2rem;
}
.blogimg{
  min-height: 25%;
  max-height: 50%;
  height: 25%;
  max-width: 75%;
  min-width: 37.5%;
  width:25%; 
}



#register{
  margin-top: 2%;
  margin-left: 25%;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #c9c1c1;
  padding: 30px;
  width: 50%;
}
.carousel { padding-bottom: 4rem; }
.carousel-caption { top: 100%; }
.carousel-inner { overflow: visible; }
.carousel-control-next, .carousel-control-prev, .carousel-indicators { bottom: 4rem; }

#date{
  justify-content: left;
}